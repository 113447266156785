import { motion } from "framer-motion";
import { useState } from "react";
import { FiMenu, FiArrowRight } from "react-icons/fi";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import cybarLogo from '../assets/imgs/SOLUTION.png'
const Navbar = () => {
  return (
    <div className="bg-gray-50 h-[74px]">
      <FlipNav />
    </div>
  );
};

const FlipNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav className="bg-slate-900 z-[20] p-4  border-gray-200 flex items-center justify-between relative">
      <NavLeft setIsOpen={setIsOpen} />
      <NavRight />
      <NavMenu isOpen={isOpen} />
    </nav>
  );
};

const Logo = () => {
  // Temp logo from https://logoipsum.com/
  return (
    <img src={cybarLogo} />
    // <svg
    //   width="50"
    //   height="39"
    //   viewBox="0 0 50 39"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="fill-gray-800"
    // >
    //   <path
    //     d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
    //     stopColor="#000000"
    //   ></path>
    //   <path
    //     d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
    //     stopColor="#000000"
    //   ></path>
    // </svg>
  );
};

const NavLeft = ({ setIsOpen }) => {
  return (
    <div className="flex items-center gap-6">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="block lg:hidden text-slate-50 text-2xl"
        onClick={() => setIsOpen((pv) => !pv)}
      >
        <FiMenu />
      </motion.button>
      <Logo />
      <NavLink text="Services" />
      <NavLink text="Community" />
      <NavLink text="Wrote about us" />
      <NavLink text="Contact" />
      <NavLink text="Questions" />
    </div>
  );
};

const NavLink = ({ text }) => {
  return (
    <Link className="hidden cursor-pointer lg:block h-[30px] overflow-hidden font-medium" smooth={true} to={text}>
      <motion.div whileHover={{ y: -30 }}>
        <span className="flex items-center h-[30px] text-slate-50">{text}</span>
        <span className="flex items-center h-[30px] text-rose-600">
          {text}
        </span>
      </motion.div>
    </Link>
    // <a
    //   href="#"
    //   rel="nofollow"
    //   className="hidden lg:block h-[30px] overflow-hidden font-medium"
    // >
    // <motion.div whileHover={{ y: -30 }}>
    //   <span className="flex items-center h-[30px] text-gray-500">{text}</span>
    //   <span className="flex items-center h-[30px] text-indigo-600">
    //     {text}
    //   </span>
    // </motion.div>
    // </a>
  );
};

const NavRight = () => {
  return (
    <div className="flex items-center gap-4">
      {/* <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="px-4 py-2 bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text text-transparent font-medium rounded-md whitespace-nowrap"
      >
        Sign in
      </motion.button> */}
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="px-4 py-2 bg-gradient-to-r from-rose-400 to-rose-600 text-white font-medium rounded-md whitespace-nowrap"
      >
        Contact Us
      </motion.button>
    </div>
  );
};

const NavMenu = ({ isOpen }) => {
  return (
    <motion.div
      variants={menuVariants}
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      className="absolute p-4 bg-slate-800 shadow-lg left-0 right-0 top-full origin-top flex flex-col gap-4"
    >
      <MenuLink text="Solutions" />
      <MenuLink text="Community" />
      <MenuLink text="Pricing" />
      <MenuLink text="Company" />
    </motion.div>
  );
};

const MenuLink = ({ text }) => {
  return (
    <motion.a
      variants={menuLinkVariants}
      rel="nofollow"
      href="#"
      className="h-[30px] overflow-hidden font-medium text-lg flex items-start gap-2"
    >
      <motion.span variants={menuLinkArrowVariants}>
        <FiArrowRight className="h-[30px] text-slate-50" />
      </motion.span>
      <motion.div whileHover={{ y: -30 }}>
        <span className="flex items-center h-[30px] text-slate-300">{text}</span>
        <span className="flex items-center h-[30px] text-slate-500">
          {text}
        </span>
      </motion.div>
    </motion.a>
  );
};

export default Navbar;

const menuVariants = {
  open: {
    scaleY: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
    },
  },
};

const menuLinkVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: -10,
    opacity: 0,
  },
};

const menuLinkArrowVariants = {
  open: {
    x: 0,
  },
  closed: {
    x: -4,
  },
};