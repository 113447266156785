
import { FiCloudLightning } from "react-icons/fi";
import { motion } from "framer-motion";



export default function Stats() {
  return (
    <div className="bg2 py-12 sm:py-32">
      <div className="mx-auto max-w-6xl ">
        <dl className="grid grid-cols-1  gap-x-8 gap-y-16 text-center place-items-center lg:grid-cols-3">
          <Card title="Cybersecurity"/>
          <Card title="Information Security"/>
          <Card title="Solutions"/>
        </dl>
      </div>
    </div>
  )
}




const Card = ({title,text,Icon}) => {
  return (
    <div className="group relative  mx-auto w-full max-w-sm overflow-hidden rounded-lg bg-transparent p-0.5 transition-all duration-500 hover:scale-[1.01] hover:bg-slate-800/50">
      <div className="relative z-10 h-[300px] flex flex-col items-center justify-center overflow-hidden rounded-[7px] bg-white p-8 transition-colors duration-500 group-hover:bg-rose-600/50">
        <FiCloudLightning className="relative z-10 mb-10 mt-2 rounded-full border-2 border-rose-900 bg-rose-600 p-4 text-7xl text-white" />

        <h4 className="relative z-10 mb-4 w-full text-3xl font-bold text-black">
          {title}
        </h4>
        <p className="relative z-10 text-black">
            {text}
        </p>
      </div>

      <motion.div
        initial={{ rotate: "0deg" }}
        animate={{ rotate: "360deg" }}
        style={{ scale: 1.75 }}
        transition={{
          repeat: Infinity,
          duration: 3.5,
          ease: "linear",
        }}
        className="absolute inset-0 z-0 bg-gradient-to-br from-indigo-200 via-indigo-200/0 to-indigo-200 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
      />
    </div>
  );
};

