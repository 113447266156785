import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './views/HomePage';
import Navbar from './cmps/Navbar';
import { Hero } from './cmps/Hero';
import CardFeatures from './cmps/Feautres';
import TerminalContact from './cmps/Contact';
import CardCarousel from './cmps/Cards';
import Reviews from './cmps/Review';
import DoubleScrollingLogos from './cmps/ScrollingLogos';
import { TabsFAQ } from './cmps/Faq';
import Galaxy from './cmps/Galaxy';
import Stats from './cmps/Stats';
import { Element } from 'react-scroll';

function App() {
  return (
    <section className='bg-slate-900'>
      <Navbar />
      <div className="relative max-h-[720px] overflow-hidden ">
        <Hero />
      </div>
      {/* <DoubleScrollingLogos /> */}
      <Stats />
      <Element name="Services" >
        <CardFeatures />
      </Element>
      <Element name="Contact">
        <TerminalContact />
      </Element>
      <CardCarousel />
      <Galaxy />
      <Element name="Wrote about us">
        <Reviews />
      </Element>
      <Element name="Questions">
      <TabsFAQ />
      </Element>
    </section >

  );
}

export default App;
