import { useAnimation, useInView } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import EncryptButton from "./EncryptBtn";
import videoBg from '../assets/videos/video2.mp4'

export const Hero = () => {
  return (

    <section className="h-[100vh] bg-slate-800 ">
      <Copy />
      <video src={videoBg} autoPlay muted playsInline className="h-[80vh] object-cover top-0 absolute" />
    </section>
  );
};


const Copy = () => {
  return (
    <div className="absolute md:bottom-0 bottom-[200px] left-0 right-0 z-[10]">
      <div className="relative mx-auto flex max-w-7xl items-end justify-between p-4 md:p-8">
        <Reveal>
        <div className="w-full">
          <h1 className="mb-6 max-w-7xl text-4xl font-black leading-[1.1] text-slate-600 md:text-8xl">
            <span className="text-rose-600 text-6xl md:text-8xl">Cybar.</span><br></br> Your Cybersecurity Solution
          </h1>
          <p className="max-w-xl text-rose-600 font-bold md:text-2xl">
            Security As It Best.
          </p>
          <div className="mt-10 flex justify-center">
            <EncryptButton />
          </div>
        </div>
        </Reveal>
      </div>
    </div>
  );
};


export const Reveal = ({ children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [isInView]);

  return (
    <div ref={ref} className="relative z-100 w-fit overflow-hidden">
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 5, delay: 5 }}
      >
        {children}
      </motion.div>
      <motion.div
        variants={{
          hidden: { left: 0 },
          visible: { left: "100%" },
        }}
        initial="hidden"
        animate={slideControls}
        transition={{ duration: 9.5, ease: "easeIn" }}
        // className="absolute bottom-1 left-0 right-0 top-1 z-20 bg-indigo-600"
      />
    </div>
  );
};